<template>
	<div class="main-contents">
		<div class="package">
			<div class="table">
				<!-- 프로젝트 지원인재 -->
				<div class="list-box">
					<fixTop :inContent="true" fixClass="top-fix">
						<template v-slot:conts>
							<div class="bt-list mt-10">
								<!-- <img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"> -->
								<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="goAppMberList()">
							</div>
							
							<!-- 프로젝트 지원인재 리스트 -->
							<span v-if="prjList.length > 0">
								<div class="itv-result-box sp_human" v-for="(prjRow, prjIdx) in prjList" :key="prjIdx">
									<div class="prj_ct">
										<div class="mark-box">
											<div class="mark bp" v-if="prjRow.projectState == '4'" >제안단계</div>
											<div class="mark ep" v-else-if="prjRow.projectState == '2'">수행예정</div>
											<div class="mark cp" v-else-if="prjRow.projectState == '9'">완료</div>
											<div class="mark pf" v-else>수행중</div>

											<!-- <div class="mark rc" v-if="prjRow.recrFinishYn == 'N' && prjRow.projectState != '03'">+모 집 중</div>
											<div class="mark cp" v-else-if="prjRow.projectState != 'N' && prjRow.projectState != '03'">모집완료</div> -->
											<!-- <div class="mark rc" v-if="prjRow.recrStartYyyymmdd <= nowYyyymmdd && prjRow.recrEndYyyymmdd >= nowYyyymmdd && prjRow.projectState != '9'">+모 집 중</div> -->
											<div v-if="prjRow.recrEndDateDiff >= 0  && prjRow.projectState != '9'" class="mark rc">+모집중</div>
											<div class="mark cp" v-else>모집완료</div>
											<div class="regDate">등록일자<span style="margin-left: 10px;">{{prjRow.regDate | date('yyyy. MM. dd')}}</span></div>
										</div>
										
										<div class="prj_outline">
											<div class="top">
												<div class="outline">
													<div class="label">프로젝트명</div>
													<div class="value prj prj-tit-hover" style="width: 750px;" @click="clickProjectInfo(prjRow.projectSeq, prjRow.projectState)">{{prjRow.projectNm}}</div>
												</div>
												<div class="outline">
													<div class="label ivl03" style="margin-right: -3px;">개요</div>
													<div class="value text">{{prjRow.projectDtlDesc}}</div>
												</div>												
												<div class="outline">
													<div class="label ivl02">고 객 사</div>
													<div style="display: inline-flex; width: 750px;">
														<div class="prj_outline_clientname">
															{{prjRow.clientNm}}
														</div>
														<div class="prj_outline_clientloc">
															<img src="/images/location.png" alt="위치" />{{prjRow.projectLocCont}}
														</div>
													</div>
												</div>
												<div class="outline">
													<div class="label ivl05">산업분야</div>
													<div class="value" style="display: inline-block !important;">
														<SelectComp type="text" cdId="PRO105" :value="prjRow.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="prjRow.indtypeClassCd"/>
													</div>
												</div>
											</div>

											<div class="outline">
												<div class="label ivl02">수 행 사</div>
												<div class="value">{{prjRow.corpNm}}</div>
											</div>											
											<div class="outline">
												<div class="label ivl05">개발기간</div>
												<div class="value">{{prjRow.projectStartYyyymmdd | date('yyyy.MM.dd')}} - {{prjRow.projectEndYyyymmdd | date('yyyy.MM.dd')}}</div>
											</div>
											<div class="outline">
												<div class="label ivl05">직무분야</div>
												<div class="value">
													<ul class="duty-list">
														<li v-for="(duty, index) in prjRow.dutyList" :key="index">
															<span>
																<SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilterCd"/>/<SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyFieldCd"/>
															</span>
														</li>
													</ul>
												</div>
											</div>

											<!-- <div class="list-view int_prj">
												<div class="label ivl05">직무분야</div>
												<div class="list">
													<ul class="duty-list">
														<li v-for="(duty, index) in project.dutyList" :key="index">
															<span>
																<SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilterCd"/>/<SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyFieldCd"/>
															</span>
														</li>
													</ul>
												</div>
											</div> -->

											<div class="outline">
												<div class="label ivl05">개발기술</div>
												<div class="value text">{{prjRow.devTechCont}}</div>
											</div> 											
											
										</div>

										<!--고객CI/지원자정보-->
										<div class="client_info">
											<div class="ci_box">
												<!-- <ImageComp type="image" alt="고객사 로고"/> -->
												<ImageComp type="image" :src="'/api/prj/tec/projectCorpImg?projectSeq='+prjRow.projectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
											</div>
											<div class="deadline">
												<span v-if="prjRow.recrEndDateDiff >= 0">
												<img class="period" src="/images/tec/prj/date.png" alt="마감기간"/> 마감 <span class="period">{{prjRow.recrEndDateDiff}}일</span> 전
												</span>
												<img class="member" src="/images/tec/prj/member_sm.png" alt="지원자수"/> <span class="ivl06">지원자 </span><span calss="member">{{prjRow.appCount}}명</span>
											</div>
										</div>										
									</div>

									<div style="padding: 20px;">
										<div class="filter itv">
											<div class="filter_box int">
												<ul>
													<li><img :class="prjRow.ordColDiv == '1' || !prjRow.ordColDiv ? 'filter' : ''" src="/images/icon_filter.png" alt="필터" /><p @click="getMberList(prjRow.projectSeq, prjIdx, '1');">평가등급 높은 순</p><span>ㅣ</span></li>
													<li><img :class="prjRow.ordColDiv == '2' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터" /><p @click="getMberList(prjRow.projectSeq, prjIdx, '2');">평가등급 낮은 순</p><span>ㅣ</span></li>
													<li><img :class="prjRow.ordColDiv == '3' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터" /><p @click="getMberList(prjRow.projectSeq, prjIdx, '3');">기술등급 높은 순</p><span>ㅣ</span></li>
													<li><img :class="prjRow.ordColDiv == '4' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터" /><p @click="getMberList(prjRow.projectSeq, prjIdx, '4');">기술등급 낮은 순</p></li>
												</ul>
											</div>
										</div>
										<div class="Board type3 sp_human">
											<table class="Board_type3 human itv">
												<colgroup>
													<col width="3%">
													<col width="10%">
													<col width="10%">
													<col width="7%">
													<col width="16%">
													<col width="8%">
													<col width="15%">
													<col width="12%">
													<col width="*">
												</colgroup>
												<thead>
													<tr>
														<th>NO</th>
														<th>지원분야</th>
														<th></th>
														<th>평가등급</th>
														<th>회원명</th>
														<th>기술등급</th>
														<th>경력</th>
														<th>학력-졸업</th>
														<th>직무</th>
													</tr>
												</thead>
												<tbody>
													<tr :class="tecMberSeq == mberRow.mberSeq && selectedPrjIdx == prjIdx ? 'click' : ''" v-for="(mberRow, mberIdx) in prjRow.mberList" :key="mberIdx" @click="tecMberSeq = mberRow.mberSeq; selectedPrjIdx = prjIdx; mberInfoYn = mberRow.mberInfoYn">
														<td class="score">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + mberIdx+ 1 }}</td>
														<td class="field" :title="mberRow.workCont"><div>{{mberRow.workCont}}</div></td>
														<td class="score"><img class="itv_img" src="/images/mic_interview.png" alt="인터뷰" v-if="mberRow.interviewStatusCd != null & mberRow.interviewStatusCd != ''"/></td>
														<td class="score"><gradeImg type="TOT_SM_EVAL" :grade="mberRow.totEvalGradeCd" /></td>
														<td class="score">{{mberRow.mberNm}} ({{mberRow.gender | gender }} / {{mberRow.birthYr | birthYear}})</td>
														<td class="score">{{mberRow.techGradeNm}}</td>
														<td class="score">{{mberRow.totWorkMonthCnt | months}}</td>
														<td class="score"><SelectComp type="text" :value="mberRow.schoolDivCd" cdId="PRO121"/></td>
														<td class="score"><span class="duty">{{mberRow.duty}}</span></td>
													</tr>
												</tbody>
											</table>
											<pagingComp :pageInfo="pageInfo" @page-click="goPage" :classNm="'mb-0'"/>
										</div>
									</div>
								</div>
							</span>
							
							<!-- 관련 결과가 없을 경우 -->
							<div class="project_box type" v-else>
								<div class="prj_pf none">
									프로젝트 지원인재가 없습니다!
								</div>
							</div>

							<!-- 페이징부분 -->
							<!-- <pagingComp :pageInfo="pageInfo" @page-click="goPage" /> -->
							<!-- <pagingComp :pageInfo="pageInfo" @page-click="goPage" :classNm="'mb-0'"/> -->
						</template>
						
					</fixTop>
				</div>

				<!-- 상세보기 -->
				<tec-profile :tecMberSeq="tecMberSeq" v-model="detailObj" @profileClose="profileClose()"/>
			</div>
		</div>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue"
import tecProfile from "@/components/highpro/TecProfile.vue";
import fixTop from "@/components/FixTop.vue";

export default {
	data() {
		return {
			corpdiv : this.$store.state.userInfo.mberDivCd,

			srcFilter : {
				// srcTxt : '',
				pageIndex : 1,
				ordCol : 'eval',
				sort : 'high',
			},
			// bottomNone : 'mb-0',
			tecMberSeq : '',
			// mberInfoYn : '',
			detailObj : {},
			prjList :[],
			pageInfo : {},

			selectedPrjIdx : 0,
			selectedOrdCol : '1',

			nowYyyymmdd : new Date().format("yyyyMMdd"),
		}
	},

	components : {
		pagingComp, gradeImg, tecProfile, fixTop
	},

	beforeMount() {
		// console.log('this param', this.$route.params.srchName);
		// if(this.$route.params.srchName != '') {
		// 	this.srcFilter.srcTxt = this.$route.params.srchName;
		// }
		this.getDcoPgmPrjAppPrjList();
	},

	mounted(){
        // this.$emit('setViewConfig', {'footer': 'left', 'quickMenu':false})
		
    },
    destroyed(){
        this.$emit('clearViewConfig')
    },

	methods : {
		getDcoPgmPrjAppPrjList() {
			var param = this.srcFilter;
			param.pageUnit = 15;
			param.pageSize = 10;
			param.corpdiv = this.corpdiv;
			param.projectSeq = this.$route.params.projectSeq != undefined ? this.$route.params.projectSeq : '';

			if(this.ordColDiv) {
				param.ordColDiv = this.ordColDiv;
			}
			if(this.ordCol) {
				param.ordCol = this.ordCol;
			}
			if(this.sort) {
				param.sort = this.sort;
			}

			// if(div) {
			// 	param.pageIndex = div;
			// 	param.ordCol = 'eval';
			// 	param.sort = 'high';
			// }
			this.$.httpPost('/api/prj/dco/pmg/getDcoPgmPrjAppPrjList', param)
				.then((res) => {					
					this.prjList = res.data.prjList;
					this.pageInfo = res.data.pageInfo;

					for(var i in this.prjList){
						
						if(this.prjList[i].dutyFieldCont){
							var tempArr = this.prjList[i].dutyFieldCont.split(',');
							this.prjList[i].dutyList = [];

							for(var j in tempArr){
								this.prjList[i].dutyList.push({dutyFilterCd : tempArr[j].substr(0,2),
																   dutyFieldCd  : tempArr[j]});
							}
						}

					}
				});
				
		},

		getMberList(seq, idx, div) {
			this.prjList[idx].ordColDiv = div;
			this.ordColDiv = div;
			
			this.ordCol = 'eval';
			this.sort = 'high';
			if('3, 4'.indexOf(div) > -1) this.ordCol = 'tech';
			if('2, 4'.indexOf(div) > -1) this.sort = 'low';
			
			var param = {};
			param.projectSeq = seq;
			param.ordCol = this.ordCol;
			param.sort = this.sort;
			param.corpdiv = this.corpdiv;
			
			param.pageIndex = 1;
			param.pageUnit = 15;

			this.$.httpPost('/api/prj/dco/pmg/getDcoPgmPrjAppMberList', param)
				.then((res) => {
					this.prjList[idx].mberList =  res.data.mberList;
					this.pageInfo = res.data.pageInfo;
				});
		},


		// 페이지 이동
		goPage(page) {
			this.srcFilter.pageIndex = page;
			this.getDcoPgmPrjAppPrjList();
		},

		// 기술인재 상세 닫기
		profileClose() {
			this.tecMberSeq = '';
			this.mberInfoYn = '';
			this.detailObj = {};
		},

		// 프로젝트 상세보기 팝업
		clickProjectInfo(projectSeq, projectRecrStatus) {
			var param = {
				projectSeq : projectSeq,
				projectRecrStatus : projectRecrStatus
			};
			this.$.popup('/dco/pmg/prj/PRJ305P01', param, {width:0, height:0})
				// .then(res => {})
				// .catch(err => {})
				;
		},

		// 일반구인 프로젝트 지원인재 목록 보기
		goAppMberList() {
			if(this.$store.state.userInfo.mberDivCd == '22') {
				this.$router.push({name : 'PRJ305M01', caller : { name : this.$route.name, params :this.input}});
			} else {
				this.$router.go(-1);
			}
		},

	}
}
</script>